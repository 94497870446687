import React, { useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import * as t from 'counterpart'
import ProductBadges from '../ProductBadges'
import { FavAction } from './FavAction'
import { CompareAction } from './CompareAction'
import { AddToCartAction } from './AddToCartAction'
import { RatingAverage } from '../../components/RatingAverage'

import '../style/ProductBox.scss'

const initWithCompare = process.env.REACT_APP_WITH_COMPARE === 'true'
let imagePrefix = process.env.REACT_APP_IMAGES_PREFIX || ''

function ProductBox({
    product,
    withLink = true,
    withRating = true,
    withAddToCart = true,
    withCompare = initWithCompare,
    withDescription,
    withQuantity,
    className = 'product-box-block',
    boxStyle,
}) {
    const { store } = useParams()
    const url = `/${store}/details/${product.slug}-${product.id}`
    const InfoTag = withLink ? Link : 'div'
    const displayCashback = !!window.portalSetting('commerce.cashback.display')
    const currentPrice =
        displayCashback && product.cash_back
            ? product.current_price - product.cash_back
            : product.current_price
            
    const stores = window.portalSettingList['stores']
    // const toggleTheme = window.portalSetting('commerce.toggle_theme.enabled')
    const themesSettings = window.themesSettings(
        stores?.filter((s) => s.store_code === store.split('-')[0])[0].theme_key || ''
    ) // fixme: should be dynamic

    const toggleTheme = themesSettings?.blocks?.productBoxLayout === '2'

    return (
        <div className={`${className} ${toggleTheme ? 'toggleTheme' : ''}`}>
            <InfoTag className="box-info" to={url}>
                <div className="top-section">
                    <img className="image" src={`${imagePrefix}${product.thumbnail}`} alt="" />
                    {!product.isGift && (
                        <div className="top-floaters">
                            <ProductBadges product={product} externalStyles={true} />
                            <div className="right-actions">
                                {/* Fav Action */}
                                <FavAction productId={product.id} />
                                {/* Compare Action */}
                                <CompareAction
                                    withCompare={withCompare}
                                    store={store}
                                    productId={product.id}
                                />
                            </div>
                        </div>
                    )}
                    {!product.isGift && (
                        <div className="bottom-floaters">
                            {/* Add TO Cart */}
                            {product.type !== 'parent' && withAddToCart && (
                                <AddToCartAction withQuantity={withQuantity} product={product} />
                            )}

                            {/* Rating */}
                            {withRating && <RatingAverage product={product} />}
                        </div>
                    )}
                </div>
                <div className="bottom-section">
                    <div>
                        <h4 className="title"> {product.name} </h4>
                        {/* {!product.name && withDescription && <p className="description">{product.description.replace(/(<([^>]+)>)/ig, " ").substr(0, 500).replaceAll("&nbsp;", "")+"..."}</p>} */}
                    </div>
                    {!product.isGift && (
                        <>
                            <div className="price">
                                {product.type === 'parent' && (
                                    <div className="starting_from">Starting From</div>
                                )}
                                {!product.current_price && <div>{t('Free')}</div>}
                                {!!product.current_price && (
                                    <div className="price-amount">
                                        <div>
                                            <span>{currentPrice.toLocaleString()}</span>{' '}
                                            {window.portalSetting('commerce.currency')}{' '}
                                        </div>
                                        {product.saved_amount &&
                                        product.cash_back &&
                                        displayCashback ? (
                                            <div className="old-price">
                                                {product.current_price.toLocaleString() +
                                                    ' ' +
                                                    window.portalSetting('commerce.currency')}
                                            
                                            </div>
                                        ) : (
                                            ''
                                        )}
                                        {product.saved_amount ||
                                        (product.cash_back && displayCashback) ? (
                                            <div className="old-price">
                                                {Math.round(product.price).toLocaleString() +
                                                    ' ' +
                                                    window.portalSetting('commerce.currency')}
                                            </div>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                )}
                            </div>
                            <div className="toggleTheme__actions">
                                {/* Add To Cart */}
                                {product.type !== 'parent' && withAddToCart && (
                                    <AddToCartAction
                                        withQuantity={withQuantity}
                                        product={product}
                                    />
                                )}
                                {/* Fav Action */}
                                <FavAction productId={product.id} />
                                {/* Compare Action */}
                                <CompareAction
                                    withCompare={withCompare}
                                    store={store}
                                    productId={product.id}
                                />
                            </div>
                            {/* {withRating && <RatingAverage product={product} />} */}
                        </>
                    )}
                </div>
            </InfoTag>
        </div>
    )
}

export default ProductBox
