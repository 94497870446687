import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import AliceCarousel from 'react-alice-carousel'
import { Instagram } from 'react-content-loader'
import qs from 'query-string'
import api from '../../../utilities/API'
import * as t from 'counterpart'

import 'react-alice-carousel/lib/alice-carousel.css'
import './style/CitiesCarousel.scss'

export default React.forwardRef(function (props = {}, ref) {
    const { hideButtons, hideDots, cardsNumber, cardsNumberMobile, filters, loadedCities } = props
    const [cities, setCities] = useState(loadedCities)
    const { store } = useParams()

    let carousel

    props = {
        ...props,
        className: 'cities-carousel-block ' + (props.className || ''),
    }

    delete props.hideButtons
    delete props.hideDots
    delete props.cardsNumber
    delete props.cardsNumberMobile
    delete props.filters
    delete props.loadedCities

    useEffect(() => {
        if (loadedCities) {
            return
        }

        api('/commerce/cities/?' + qs.stringify(filters), {
            result: (res) => setCities(res.data || []),
        })
    }, [filters, loadedCities])

    let boxes = []
    if (cities) {
        boxes = cities.map((city, key) => (
            <Link to={`/${store}/browse?city=${city.id}`} className="city">
                {city.name}
            </Link>
        ))
    } else {
        boxes = [1, 2, 3, 4].map((key) => <Instagram key={key} className="placeholder" />)
    }

    const noResults = cities && cities.length === 0

    return (
        <div {...props} ref={ref}>
            {props.title && <h3>{props.title}</h3>}

            {!noResults && (
                <AliceCarousel
                    autoPlay={props.autoPlay}
                    disableDotsControls={hideDots}
                    disableButtonsControls
                    animationDuration={500}
                    swipeDelta={100}
                    infinite
                    mouseTracking
                    animationEasingFunction="ease-in-out"
                    touchMoveDefaultEvents={false}
                    responsive={{
                        0: { items: cardsNumberMobile || 4 },
                        960: { items: cardsNumber || 8 },
                    }}
                    ref={(el) => (carousel = el)}
                >
                    {boxes}
                </AliceCarousel>
            )}

            {noResults && <div className="no-results">{t("No results in this section right now")}</div>}

            {!hideButtons && !noResults && (
                <div className="buttons">
                    <i className="left fa fa-chevron-left" onClick={() => carousel.slidePrev()} />
                    <i className="right fa fa-chevron-right" onClick={() => carousel.slideNext()} />
                </div>
            )}
        </div>
    )
})
