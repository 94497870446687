import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'react-bootstrap'
import api from '../../utilities/API'
import { addToCompare } from './actions'
import t from 'counterpart'

import './style/Compare.scss'

let imagePrefix = process.env.REACT_APP_IMAGES_PREFIX || ''

function Compare() {
    const dispatch = useDispatch()
    const compare = useSelector((state) => state.commerce.compare)
    const { store } = useParams()
    const [products, setProducts] = useState([])

    // load products
    useEffect(() => {
        setProducts([])

        compare.data.forEach((id) => {
            api('/commerce/products/' + id, {
                result: (data) => setProducts((products) => [...products, data]),
            })
        })
    }, [compare])

    // remove
    const onRemove = (id) => {
        let newList = [...compare.data]
        newList.splice(newList.indexOf(id), 1)
        dispatch(addToCompare(newList))
    }

    // rest
    const onReset = () => dispatch(addToCompare([]))

    if (!products.length) {
        return <div id="commerce-compare-page" className="container">
            <h1>{t('no products available')}</h1>
        </div>
    }

    return (
        <div id="commerce-compare-page" className="container">
            <h1>
                {t('Compare')} {t('Products')}
            </h1>

            <div className="products">
                {products.map((product) => (
                    <div className="product-box-block" key={product.id}>
                        <Link to={`/${store}/details/${product.slug}-${product.id}`}>
                            <div
                                className="image"
                                style={{
                                    backgroundImage:
                                        "url('" + imagePrefix + product.thumbnail + "')",
                                }}
                            />
                            <h4>{product.name}</h4>

                            <div className="price">
                                {product.current_price} {window.portalSetting('commerce.currency')}
                                <span> {t('VAT Included')} </span>
                                <div className="old-price">
                                    {product.saved_amount
                                        ? product.price + window.portalSetting('commerce.currency')
                                        : ''}{' '}
                                </div>
                            </div>
                        </Link>

                        {(product.attributes_set.attributes || []).map((attr) => (
                            <div className="attribute" key={attr.id}>
                                <span>{attr.label}: </span>
                                {product[attr.key]}
                            </div>
                        ))}

                        <i className="fa fa-times remove" onClick={() => onRemove(product.id)} />
                    </div>
                ))}
            </div>

            {products.length !== 0 && (
                <Button id="reset" onClick={onReset}>
                    {t('re-compare')}
                </Button>
            )}
        </div>
    )
}

export default Compare
