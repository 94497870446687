export type AddressesProps = {
    is_order_business: boolean
    onChange: (id: number | null) => void
    review?: boolean
}
export type NewAddressFormType = {
    id?: number | null
    name: string
    country: string
    state: string
    city: string
    street: string
    building: string
    floor: string
    apartment: string
    neighborhood: string
    postal_code: string
    plot_number: string
    registry_name: string
    tax_number: string
    registry_number: string
    lat: number | null
    lng: number | null
    default: boolean
}

export type AddressFormProps = {
    selectedId: number | null
    setNewAddressForm: Function
    newAddressForm: NewAddressFormType
    onAddressFormChange: any
    onSubmitAddressSucess: any
    is_order_business: boolean
    proceedWithAddressWithoutSaving?: boolean
}

export type ErrorsStateType = {
    message?: string
    validation: {
        name?: string
        country?: string
        state?: string
        city?: string
        neighborhood?: string
        street?: string
        postal_code?: string
        plot_number?: string
        registry_name?: string
        tax_number?: string
        registry_number?: string
        building?: string
        floor?: string
        apartment?: string
    }
}


export const INITIAL_ADDRESS_FORM = {
    name: '',
    country: '',
    state: '',
    city: '',
    street: '',
    building: '',
    floor: '',
    apartment: '',
    neighborhood: '',
    postal_code: '',
    plot_number: '',
    registry_name: '',
    tax_number: '',
    registry_number: '',
    lat: null,
    lng: null,
    default: false,
}