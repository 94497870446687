import React from 'react'
import { Route } from 'react-router-dom'
import Reducer from './reducer'

import PlaceOrder from './PlaceOrder'
import Confirm from './Confirm'
import Shipping from './Shipping'
import PaymentLink from './PaymentLink'
import Subscribe from './Subscription/Subscribe'
import CartPopup from './Blocks/CartPopup'
import { CartPage } from './CartPage'

const POS = React.lazy(() => import('./POS'))

let routes = [
    <Route key="cart_page" exact path="/cart" element={<CartPage />} />,
    <Route key="cart_order" exact path="/cart/order" element={<PlaceOrder />} />,
    <Route key="cart_payment" exact path="/cart/confirm/:id" element={<Confirm />} />,
    <Route key="cart_shipping" exact path="/cart/shipping/:id" element={<Shipping />} />,
    <Route key="cart_pos" exact path="/pos" element={<POS />} />,
    <Route key="cart_pay_link" exact path="/cart/pay/link/:reference" element={<PaymentLink />} />,
]

if (process.env.REACT_APP_ENABLE_SUBSCRIPTION) {
    routes.push(<Route key="cart_subscribe" exact path="/subscribe" element={<Subscribe />} />)
}

const cartInit = {
    routes,
    reducers: { cart: Reducer },
    blocks: {
        'cart-popup': {
            component: CartPopup,
            setting: {},
        },
    },
    i18n: {
        en: {
            time: 'Time',
            cash: 'Cash',
            cashier_id: 'Cashier ID',
            cashier_name: 'Cashier',
            transaction_id: 'Transaction ID',
            payment_method: 'Payment Method',
            discounts: 'Discounts',
            invoice: 'Invoice',
            business_address: 'Business Address',
            apply_discount: 'Apply Discount',
            discount_amount: 'Discount Amount',
            discount_percent: 'Discount Percentage',
            product_settings: 'Product Settings',
            pos_settings: 'POS Settings',
            product_details: 'Product Details',
            product_name: 'Product Name',
            total_saved: 'Total Saved',
            cash_back: 'Cash Back',
            discount_settings: 'Discount Settings',
            save: 'Save',
            cancel: 'Cancel',
            max_allowed_discount: 'Max Allowed Discount',
            value_must_be_a_number: 'Value must be a number',
            max_discount_excedded: 'Discount amount cannot exceed max allowed amount',
            delivery_method: 'Delivery Method',
            confirm_and_continue: 'Confirm and Continue',
            quantity_short: 'Qty',
            use_your_points: 'Use your points',
            'This request will add': 'This request will add',
            'to your account': 'to your account.',
            available: 'Available',
            you_must_agree_to_terms_before_you_can_proceed:
                'You must agree to the terms before you can proceed.',
            not_enough_points: 'Not enough points.',
            negative_total: 'Order total cannot be negative.',
            points_limit_exceeded: 'Points cannot be used when total paid exceeds order total',
            max_points_allowed: 'Max Points Allowed',

            use_points: 'Use Points',

            //cash withdrawls
            cash_withdrawls: 'Cash Withdrawls',
            amount_to_withdraw: 'Amount to Withdraw',
            enter_amount_to_withdraw: 'Enter amount to withdraw',
            withdrawl_notes: 'Withdrawl Notes',
            supervisor_id: 'Supervisor ID',
            enter_id: 'Enter ID',
            supervisor_password: 'Supervisor Password',
            enter_password: 'Enter password',
            enter_withdrawls_notes: 'Enter withdrawl notes',
            withdraw: 'Withdraw',
            cash_amount: 'Cash Amount',
            cash_withdrawl_excuted: 'Cash withdrawl excuted successfully',

            //validation
            // POS
            // POS Settings
            shift: 'Shift',
            // SHIFT
            shift_details: 'Shift Details',

            online_amount: 'Online',
            close_shift: 'Close Shift',
            closing: 'Closing',
            actual_closing_cash: 'Actual Closing Cash',
            enter_actual_closing_cash: 'Enter Actual Closing Cash',
            closing_note: 'Closing Note',
            enter_closing_note: 'Enter Closing Note',
            shift_closed_successfully: 'Shift closed successfully',

            // validations:
            please_correct_errors_in_the_form: 'Please correct errors in the form',
            actual_closing_cash_is_required: 'Actual closing cash is required',
            supervisor_id_is_required: 'Supervisor ID is required',
            supervisor_password_is_required: 'Supervisor password is required',
            withdrawl_amount_is_required: 'Withdrawl amount is required',
            withdrawl_amount_is_greater_than_available_cash:
                'Withdrawl amount is greater than available cash',
            error_fetching_shift: 'Error fetching shift',
            //start shift
            hi: 'Hi',
            start_shift: 'Start Shift',
            shift_started_successfully: 'Shift started successfully',
            device: 'Device',
            select_device: 'Select Device',
            starting_money: 'Starting Money',
            actual_starting_money: 'Actual Starting Money',
            enter_starting_money: 'Enter Starting Money',
            starting_note: 'Starting Note',
            enter_starting_note: 'Enter Starting Note',
            logout: 'Logout',
            points: 'Points',
            // validations:
            actual_starting_cash_is_required: 'Actual starting cash is required',
            device_is_required: 'Device is required',

            printing_settings: 'Printing Settings',
            printing_group: 'Printing Group',
            categories: 'Categories',
            printers: 'Printers',
        },

        ar: {
            cashier_name: 'اسم الكاشير',
            subtotal: 'اجمالي جزئي',
            time: 'الوقت',
            cash: 'نقداً',
            cashier_id: 'رقم الكاشير',
            transaction_id: 'رقم العملية',
            payment_method: 'طريقة الدفع',
            discounts: 'الخصومات',
            invoice: 'الفاتورة',
            cash_back: 'استرداد نقدي',
            quantity_short: 'عدد',
            SAR: 'ريال',
            'Coupon not valid': 'الكود غير صحيح',
            business_address: 'عنوان عمل',
            apply_discount: 'تطبيق الخصم',
            discount_amount: 'مبلغ الخصم',
            discount_percent: 'نسبة الخصم',
            product_settings: 'إعدادات المنتج',
            pos_settings: 'إعدادات نقطة البيع',
            product_details: 'تفاصيل المنتج',
            product_name: 'اسم المنتج',
            total_saved: 'المجموع المدخر',
            discount_settings: 'إعدادات الخصم',
            save: 'الحفظ',
            cancel: 'إلغاء',
            max_allowed_discount: 'الحد الأقصى للخصم',
            value_must_be_a_number: 'يجب أن يكون القيمة رقمًا',
            max_discount_excedded: 'لا يمكن أن يتجاوز مبلغ الخصم الحد الأقصى المسموح به',
            delivery_method: 'طريقة التوصيل',
            confirm_and_continue: 'تأكيد ومتابعة',
            use_your_points: 'إستخدم نقاطك',
            'This request will add': 'هذا الطلب سيضيف',
            'to your account': ' نقطة لحسابك.',
            available: 'متاح',
            you_must_agree_to_terms_before_you_can_proceed:
                'يجب أن توافق على الشروط قبل أن تتمكن من المتابعة.',
            not_enough_points: 'النقاط غير كافية.',
            negative_total: 'إجمالي الطلب لا يمكن أن يكون سالبًا.',
            points_limit_exceeded:
                'لا يمكن استخدام النقاط عندما يتجاوز المبلغ المدفوع إجمالي للطلب',
            max_points_allowed: 'الحد الأقصى للنقاط المسموح بها',
            use_points: 'استخدام النقاط',

            //cash withdrawls
            cash_withdrawls: 'سحب نقدي',
            amount_to_withdraw: 'المبلغ المراد سحبه',
            enter_amount_to_withdraw: 'أدخل المبلغ المراد سحبه',
            withdrawl_notes: 'ملاحظات السحب',
            enter_withdrawls_notes: 'أدخل ملاحظات السحب',
            // POS
            // POS Settings
            shift: 'الوردية',
            // SHIFT
            shift_details: 'تفاصيل الوردية',
            online_amount: 'الكتروني',
            close_shift: 'إغلاق الوردية',
            closing: 'جاري الإغلاق',
            actual_closing_cash: 'النقد الفعلي عند الإغلاق',
            enter_actual_closing_cash: 'أدخل النقد الفعلي',
            closing_note: 'ملاحظة الإغلاق',
            enter_closing_note: 'أدخل ملاحظة الإغلاق',
            shift_closed_successfully: 'تم إغلاق الوردية بنجاح',
            supervisor_id: 'رقم المشرف',
            enter_id: 'أدخل الرقم',
            supervisor_password: 'كلمة مرور المشرف',
            enter_password: 'أدخل كلمة المرور',
            withdraw: 'سحب',
            cash_amount: 'المبلغ النقدي',
            cash_withdrawl_excuted: 'تم تنفيذ عملية السحب النقدي بنجاح',
            //validation
            withdrawl_amount_is_required: 'المبلغ المراد سحبه مطلوب',
            withdrawl_amount_is_greater_than_available_cash:
                'المبلغ المراد سحبه أكبر من المبلغ المتاح',
            please_correct_errors_in_the_form: 'يرجى تصحيح الأخطاء في النموذج',
            supervisor_id_is_required: 'رقم المشرف مطلوب',
            supervisor_password_is_required: 'كلمة مرور المشرف مطلوبة',
            error_fetching_shift: 'حدث خطأ أثناء جلب الوردية',
            // validations:

            actual_closing_cash_is_required: 'النقد الفعلي عند الإغلاق مطلوب',

            //start shift
            hi: 'مرحبا',
            start_shift: 'بدء الوردية',
            shift_started_successfully: 'تم بدء الوردية بنجاح',
            device: 'الجهاز',
            select_device: 'اختر الجهاز',
            starting_money: 'النقد المسجل',
            actual_starting_money: 'النقد الحالي',
            enter_starting_money: 'ادخل النقد الحالي',
            starting_note: 'ملاحظة البدء',
            enter_starting_note: 'ادخل ملاحظة البدء',
            logout: 'تسجيل الخروج',
            points: 'نقاط',
            // validations:
            actual_starting_cash_is_required: 'المبلغ الفعلي عند البدء مطلوب',
            device_is_required: 'الجهاز مطلوب',

            printing_settings: 'إعدادات الطباعة',
            printing_group: 'مجموعة الطباعة',
            'Printers Groups': 'مجموعات الطابعات',
            'Add Printing Group': 'إضافة مجموعة طباعة',
            'Group Name': 'اسم المجموعة',
            categories: 'الفئات',
            printers: 'الطابعات',
            Actions: 'الإجراءات',
            'Add Printer': 'إضافة طابعة',
            Name: 'الاسم',
            Ip: 'عنوان IP',
            Port: 'المنفذ',
        },
    },
}

export default cartInit
