import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Link } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import * as t from 'counterpart'

import { addToCompare } from '../../actions'
import { ToolTip } from '../../components/Tooltip'

import '../style/ProductBox.scss'

import { ReactComponent as ComprIcon } from '../style/nsaq-icon-cmpare.svg'

export const CompareAction = ({ withCompare, store, productId }) => {
    const dispatch = useDispatch()
    const { compare } = useSelector((state) => ({
        compare: state.commerce.compare,
    }))

    const compareIdx = compare.data.indexOf(productId)
    const inCompare = compareIdx !== -1

    const toggleCompare = (e) => {
        e.stopPropagation()
        e.preventDefault()
        let newList = [...compare.data]
        if (inCompare) {
            newList.splice(compareIdx, 1)
        } else {
            newList.push(productId)
        }

        dispatch(addToCompare(newList.slice(-4)))
    }

    return (
        <>
            {/* Compare action */}
            <div className={inCompare ? 'compare-buttons-wrapper' : ''}>
                {withCompare && (
                    <ToolTip label={t('Compare')}>
                        <Button
                            className={'add-to-compare ' + (inCompare ? 'success' : '')}
                            onClick={toggleCompare}
                        >
                            <ComprIcon />
                        </Button>
                    </ToolTip>
                )}
                {inCompare && (
                    <ToolTip label={t('Go to compare')}>
                        <Link to={`/${store}/compare`}>
                            <Button className={'go-to-compare'}>
                                <i className="fas fa-external-link-alt" />
                            </Button>
                        </Link>
                    </ToolTip>
                )}
            </div>
        </>
    )
}
