import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Spinner, Button } from 'react-bootstrap'
import * as t from 'counterpart'
import api from '../../utilities/API'

import './style/Confirm.scss'

function Confirm({ match }) {
    const [order, setOrder] = useState(null)
    const isSuccessful = order && (order.status === 'paid' || !order.payment_method_id || order.payment_driver === 'onsite' || order.paid) 
    let { store, id } = useParams()

    id = id.split('-')
    id = id.length > 2 ? id[1] : id[0]

    useEffect(() => {
        api('/commerce/orders/' + id, {
            // by=payment_id
            result: (data) => setOrder(data),
            error: console.log,
        })
    }, [id])

    return (
        <div id="cart-confirm-page" className="container page-body">
            {!order && <Spinner animation="border" size="xl" />}
            {order && (
                <div>
                    <i className={'fas fa-' + (isSuccessful ? 'check' : 'times')} />
                    <h6>
                        {t(
                            isSuccessful
                                ? 'Your order is completed, Thank you for purchasing with us.'
                                : 'Failed to check the payment please check your orders to continue.'
                        )}
                    </h6>
                    <Button as={Link} to={`/${store}/orders/${id}`}>
                        {t('Check your order details')}
                    </Button>
                </div>
            )}
        </div>
    )
}

export default Confirm
