import React from 'react'
import { Route } from 'react-router-dom'
import Reducer from './reducer'
import Category from './Category'
import Product from './Product'
import Compare from './Compare'
import Orders from './Orders'
import OrderDetails from './OrderDetails/OrderDetails'
import OrderSummary from './QRPaymentProcessor/TableOrderSummary'
import TableReceipt from './QRPaymentProcessor/TableReceipt'
import Wishlist from './Wishlist'
import i18n from './i18n'
import OrderConfirmation from './OrderConfirmation'
import Addresses from './Addresses'
import Sellers from './Sellers'
import Seats from './Seats'

// blocks
import BrandsCarousel from './Blocks/BrandsCarousel'
import FavoritesPopup from './Blocks/FavoritesPopup'
import ProductsList from './Blocks/ProductsList'
import ProductsCarousel from './Blocks/ProductsCarousel'
import SellerCarousel from './Blocks/SellerCarousel'
import Search from './Blocks/Search'
import StoreSwitcher from './Blocks/StoreSwitcher'
import Categories from './Blocks/Categories'
import CategoriesProductsList from './Blocks/CategoriesProductsList'
import CitiesCarousel from './Blocks/CitiesCarousel'
import TableOrderDetails from './TableOrderDetails/TableOrderDetails'

const Locations = React.lazy(() => import('./Locations'))

const commerceInit = {
    i18n,
    reducers: { commerce: Reducer },
    routes: [
        <Route key="commerce_category" exact path="/browse" element={<Category />} />,
        <Route key="commerce_category" exact path="/browse/:category" element={<Category />} />,
        <Route key="commerce_product" exact path="/details/:slug" element={<Product />} />,
        <Route key="commerce_compare" exact path="/compare" element={<Compare />} />,
        <Route key="commerce_orders" exact path="/orders" element={<Orders />} />,
        <Route key="commerce_order" exact path="/orders/:id" element={<OrderDetails />} />,

        <Route key="resturant_order" exact path="/tables/:id" element={<TableOrderDetails />} />,

        <Route
            key="commerce_order_payment"
            exact
            path="/tables/payment/:id"
            element={<OrderSummary />}
        />,
        // <Route
        //     key="commerce_order_payment"
        //     exact
        //     path="/tables/receipt/:id"
        //     element={<TableReceipt />}
        // />,
        <Route key="commerce_wishlist" exact path="/wishlist" element={<Wishlist />} />,
        <Route key="commerce_addresses" exact path="/addresses" element={<Addresses />} />,
        <Route key="commerce_confirm" exact path="/confirmation" element={<OrderConfirmation />} />,
        <Route key="commerce_sellers" exact path="/sellers" element={<Sellers />} />,
        <Route key="commerce_sellers" exact path="/locations" element={<Locations />} />,
        <Route key="commerce_sellers" exact path="/seats/:mapID/:eventID" element={<Seats />} />,
    ],
    blocks: {
        'brands-carousel': {
            component: BrandsCarousel,
            setting: {},
        },
        'favorites-popup': {
            component: FavoritesPopup,
            setting: {},
        },
        'products-list': {
            component: ProductsList,
            setting: {},
        },
        'products-carousel': {
            component: ProductsCarousel,
            setting: {
                title: { form: 'text' },
                hideButtons: { form: 'checkbox' },
                hideDots: { form: 'checkbox' },
                extended: { form: 'checkbox' },
                cardsNumber: { form: 'number' },
                cardsNumberMobile: { form: 'number' },
                filters: { form: 'text' },
                boxStyle: { form: 'checkbox' },
            },
        },
        'seller-carousel': {
            component: SellerCarousel,
            setting: {
                title: { form: 'text' },
                hideButtons: { form: 'checkbox' },
                hideDots: { form: 'checkbox' },
                extended: { form: 'checkbox' },
                cardsNumber: { form: 'number' },
                cardsNumberMobile: { form: 'number' },
                filters: { form: 'text' },
            },
        },
        'commerce-search': {
            component: Search,
            setting: {},
        },
        'store-switcher': {
            component: StoreSwitcher,
            setting: {},
        },
        'commerce-categories': {
            component: Categories,
            setting: {},
        },
        'commerce-categories-products': {
            component: CategoriesProductsList,
            setting: {},
        },
        'commerce-cities-carousel': {
            component: CitiesCarousel,
            setting: {
                title: { form: 'text' },
                hideDots: { form: 'checkbox' },
                extended: { form: 'checkbox' },
                cardsNumber: { form: 'number' },
                cardsNumberMobile: { form: 'number' },
            },
        },
        'commerce-orders': {
            component: Orders,
            setting: {
                title: { form: 'text' },
            },
        },
    },
    navigation: {
        menu: [
            {
                id: 'example',
                order: 1,
                name: 'ExampleApp',
                icon: 'fa fa-truck',
                link: '/example/app/route',
                pages: [{ name: 'Sub Link', link: '/example/app/route' }],
            },
        ],
    },
}

export default commerceInit
