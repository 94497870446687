import React, { useState, useEffect, useRef } from 'react'
import { Link, useParams, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import {Button, Navbar, Nav, NavDropdown, Offcanvas, Modal, Form, Toast, ToastContainer} from 'react-bootstrap'
import * as t from 'counterpart'
import { flushStorage } from '../../../utilities/storage'
import apps from './links'

import './AdminBar.scss'

export const isSaas = process.env.REACT_APP_SAAS_ENABLED === 'true'

function AdminBar() {
    const store = useParams().store
    const user = useSelector((state) => state.portal.user)
    const location = useLocation().pathname.split('/')
    const [search, setSearch] = useState(null)
    const offcanvasRef = useRef(null)
    const [toasts, setToasts] = useState([])

    useEffect(() => offcanvasRef.current?.backdrop?.click(), [location])

    // main app
    let currentApp = location.length > 2 ? location[3] : null
    if (
        currentApp === 'broadcast' ||
        currentApp === 'system' ||
        (currentApp === 'erp' && location[4] === 'setting')
    ) {
        currentApp = 'setting'
    }
    if (!currentApp || !apps[currentApp]) {
        currentApp = 'commerce'
    }

    const app = apps[currentApp]
    const subApps = app.sub || {}

    // sub app
    let currentSubApp = location.length > 4 ? location[4] : null
    if (!currentSubApp || !subApps[currentSubApp]) {
        currentSubApp = Object.keys(subApps)[0]
    }

    const appLinks = subApps[currentSubApp]?.links || []
    const isRTL = t('dir') === 'rtl'

    // toasts
    useEffect(() => {
        document.addEventListener('admin_toast', function (e) {
            setToasts(toasts => [e.detail, ...toasts])
        });
    }, [])

    const removeToast = (i) => {
        setToasts(toasts => {
            toasts.splice(i, 1)
            return [...toasts]
        })
    }

    return (
        <>
            <Navbar id="admin-bar" sticky="top" bg="dark" variant="dark" expand="lg" collapseOnSelect>
                <Button className="home-btn" as={Link} to={'/' + store + '/admin'}>
                    <i className="fa-solid fa-chart-pie"></i>
                </Button>

                <Nav className="apps-dropdown" style={{ backgroundColor: app.bg }}>
                    <NavDropdown
                        title={
                            <h6>
                                {t(currentApp)} <i className={'fa fa-chevron-' + t('right')} />
                            </h6>
                        }
                        align={isRTL ? 'start' : 'end'}
                    >
                        {Object.entries(apps).map(([key, a]) =>
                            !a.permission || user.can(a.permission) ? (
                                <NavDropdown.Item
                                    key={key}
                                    as={Link}
                                    to={a.link.replace('{store}', store)}
                                    style={{ backgroundColor: a.bg }}
                                >
                                    <i className={a.icon} /> <div>{t(a.text || key)}</div>
                                </NavDropdown.Item>
                            ) : null
                        )}
                    </NavDropdown>
                </Nav>

                {Object.keys(subApps).length > 1 && (
                    <Nav className="apps-dropdown sub-apps">
                        <NavDropdown
                            title={
                                <h6>
                                    {t(currentSubApp)} <i className="fa fa-chevron-down" />
                                </h6>
                            }
                            align={isRTL ? 'start' : 'end'}
                        >
                            {Object.entries(subApps).map(([key, a]) => (
                                <NavDropdown.Item
                                    key={key}
                                    as={Link}
                                    to={a.link.replace('{store}', store)}
                                >
                                    <i className={a.icon} /> <div>{t(a.text || key)}</div>
                                </NavDropdown.Item>
                            ))}
                        </NavDropdown>
                    </Nav>
                )}

                <Navbar.Toggle aria-controls="admin-nav" />

                <Navbar.Offcanvas id="admin-nav" dir={t('dir')} ref={offcanvasRef}>
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>{t('Admin')}</Offcanvas.Title>
                    </Offcanvas.Header>

                    <Offcanvas.Body>
                        {appLinks.map((dropdown, i) => {
                            if (dropdown.permission && !user.can(dropdown.permission)) {
                                return
                            }

                            if (dropdown.to) {
                                return (
                                    <Nav key={i}>
                                        <Nav.Link as={Link} to={dropdown.to.replace('{store}', store)}>
                                            <i className={dropdown.icon} /> {t(dropdown.text)}
                                        </Nav.Link>
                                    </Nav>
                                )
                            }

                            const links = dropdown.links.filter(
                                (l) => !l.permission || user.can(l.permission)
                            )
                            if (!links.length) {
                                return null
                            }

                            return (
                                <Nav key={i}>
                                    <NavDropdown
                                        title={
                                            <>
                                                <i className={dropdown.icon} /> {t(dropdown.text)}{' '}
                                                <i className="fa fa-chevron-down" />
                                            </>
                                        }
                                        align={isRTL ? 'start' : 'end'}
                                    >
                                        {links.map((link, ii) => (
                                            <NavDropdown.Item
                                                key={ii}
                                                as={Link}
                                                to={link.to.replace('{store}', store)}
                                            >
                                                {t(link.text)}
                                            </NavDropdown.Item>
                                        ))}
                                    </NavDropdown>
                                </Nav>
                            )
                        })}

                        <Nav className="setting-btn">
                            <Nav.Link as={Link} to={`/${store}/`}>
                                <i className="fa fa-store" /> {t('Store')}
                            </Nav.Link>
                            {isSaas && (
                                <Nav.Link onClick={() => setSearch('')}>
                                    <i className="fa fa-magnifying-glass" />
                                </Nav.Link>
                            )}
                            <Nav.Link onClick={flushStorage}>
                                <i className="fas fa-sign-out-alt" />
                            </Nav.Link>
                        </Nav>
                    </Offcanvas.Body>
                </Navbar.Offcanvas>

                <Modal
                    aria-labelledby="contained-modal-title-vcenter"
                    dir={t('dir')}
                    centered
                    show={search !== null}
                    onHide={() => setSearch(null)}
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter" style={{ width: '90%' }}>
                            <Form.Control onChange={(e) => setSearch(e.target.value)} />
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            overflow: 'auto',
                            height: 400,
                        }}
                    >
                        {apps && Object.entries(apps)?.map(([appKey, app]) =>
                            Object.entries(app?.sub)?.map(([subKey, subApp]) =>
                                subApp.links?.map((dropdown) => {
                                    if (!dropdown.links) {
                                        dropdown.links = [{ ...dropdown }]
                                    }

                                    const links = dropdown.links.filter((l) => {
                                        const allowed = !l.permission || user.can(l.permission)
                                        const matched =
                                            !search ||
                                            l.text.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
                                            t(l.text).indexOf(search) !== -1
                                        return allowed && matched
                                    })

                                    if (!links.length) {
                                        return null
                                    }

                                    return links.map((link, i) => (
                                        <Link
                                            key={`${appKey}-${subKey}-${i}`}
                                            to={link.to.replace('{store}', store)}
                                            onClick={() => setSearch(null)}
                                            className="p-2"
                                        >
                                        <span style={{ color: '#aaa' }}>
                                            {t(app.text || appKey)}{' '}
                                            <i className={t('fa fa-chevron-' + t('right'))}></i>{' '}
                                            {subKey !== 'main' && (
                                                <>
                                                    {t(subApp.text || subKey)}{' '}
                                                    <i className="fa fa-chevron-left"></i>
                                                </>
                                            )}
                                        </span>
                                            {t(link.text)}
                                        </Link>
                                    ))
                                })
                            )
                        )}
                    </Modal.Body>
                </Modal>
            </Navbar>

            <ToastContainer position="bottom-end" containerPosition="fixed" style={{ zIndex: 1, margin: 5 }}>
                {toasts.map((toast, i) => (
                    <Toast
                        key={i}
                        bg={"success"}
                        autohide
                        animation
                        delay={toast.delay || 5000}
                        onClick={() => removeToast(i)}
                        onClose={() => removeToast(i)}
                    >
                        <Toast.Body className="text-white">{toast.body}</Toast.Body>
                    </Toast>
                ))}
            </ToastContainer>
        </>
    )
}

export default AdminBar
