import React, { useState, useEffect } from 'react'
import { Link, Navigate, useParams } from 'react-router-dom'
import { Badge, Spinner } from 'react-bootstrap'
import ReactPaginate from 'react-paginate'
import moment from 'moment'
import t from 'counterpart'
import { useSelector } from 'react-redux'
import api from '../../utilities/API'
import Ticket from './Ticket'
import './style/Orders.scss'

const isTicketing = process.env.REACT_APP_TICKETING
let imagePrefix = process.env.REACT_APP_IMAGES_PREFIX || ''

function Orders({ title }) {
    const { ready, user } = useSelector((state) => state.portal)
    const [orders, setOrders] = useState({ isLoading: true })
    const store = useParams().store

    const load = (page = 0) => {
        api('/commerce/orders/?page=' + page, {
            result: (data) => {
                setOrders(data)
                window.scrollTo({ top: 0, behavior: 'smooth' })
            },
        })
    }

    //
    useEffect(() => {
        if (ready && user) {
            load()
        }
    }, [ready, user])

    if (!ready) {
        return (
            <Spinner
                animation="border"
                variant="primary"
                style={{ position: 'fixed', top: '50%', left: '50%' }}
            >
                <span className="sr-only">Loading...</span>
            </Spinner>
        )
    }

    if (!user) {
        return <Navigate to={`/${store}/auth/login`} replace />
    }

    return (
        <div id="commerce-orders-pages" className="container">
            <h1>{title || t('Orders')}</h1>

            {(orders.data || []).map((order, i) => {
                let statusLabel = order.status
                if (statusLabel === 'pending' && order.payment_method_id && !order.paid) {
                    statusLabel = 'not_confirmed'
                }

                return (
                    <Link to={`/${store}/orders/${order.id}`} className="order" key={order.id}>
                        <div className="details row">
                            <div className="col-md-3">
                                {t(order.return ? 'Refund Number' : 'Order Number')} #
                                {order.id + 1000}
                            </div>
                            <div className="col-md-3">
                                {t('Total')}: {Math.abs(order.total).toLocaleString()}
                            </div>
                            <div className="col-md-2">
                                <Badge bg={order.status === 'completed' ? 'success' : 'warning'}>
                                    {t(statusLabel)}
                                </Badge>
                            </div>
                            <div className="col-md-2">
                                <>
                                    <i
                                        className={
                                            !order.payment_method_id
                                                ? 'fa fa-money-bill m-1'
                                                : 'fa fa-credit-card m-1'
                                        }
                                    />
                                    {t(
                                        order.payment_method_id && order.payment_driver !== 'onsite'
                                            ? 'Online Payment'
                                            : 'Cash Payment'
                                    )}
                                </>
                                {!order.return && Number(order.redeemed_cash) ? (
                                    <div className="d-block">
                                        <i class="fa-solid fa-coins m-1"></i>
                                        {t('points_cash')}
                                    </div>
                                ) : (
                                    ''
                                )}
                            </div>
                            <div className="date col-md-2">
                                {
                                    // todo: find a better way to handle this
                                    moment(order.created_at)
                                        .calendar()
                                        .replace(
                                            /[٠١٢٣٤٥٦٧٨٩]/g,
                                            (digit) =>
                                                ({
                                                    '٠': '0',
                                                    '١': '1',
                                                    '٢': '2',
                                                    '٣': '3',
                                                    '٤': '4',
                                                    '٥': '5',
                                                    '٦': '6',
                                                    '٧': '7',
                                                    '٨': '8',
                                                    '٩': '9',
                                                })[digit]
                                        )
                                }
                            </div>
                        </div>

                        <div className="items row">
                            {order.items?.map((item) => {
                                if (isTicketing && item.status === 'paid') {
                                    return (
                                        <Ticket
                                            key={item.id}
                                            className="item"
                                            data={item}
                                            user={user}
                                            order={order}
                                        />
                                    )
                                }

                                return (
                                    <div className="item col-md-4 row" key={item.id}>
                                        <div className="col-md-4">
                                            <img
                                                src={imagePrefix + item.product?.thumbnail}
                                                alt=""
                                            />
                                        </div>
                                        <div className="col-md-8">
                                            x{Math.abs(item.quantity)} {item.product?.name}
                                            <h5>
                                                {item.total
                                                    ? Math.abs(item.total).toLocaleString()
                                                    : t('Free')}
                                            </h5>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </Link>
                )
            })}

            {!orders.isLoading && !!orders.data?.length && orders.to > 1 && (
                <ReactPaginate
                    forcePage={orders.current_page - 1}
                    pageCount={orders.to}
                    onPageChange={({ selected }) => {
                        load(selected + 1)
                    }}
                    previousLabel={t('Previous')}
                    nextLabel={t('Next')}
                    className="pagination"
                    pageClassName="page-item"
                    previousClassName="page-item"
                    nextClassName="page-item"
                    breakClassName="page-item"
                    activeClassName="active"
                    pageLinkClassName="page-link"
                    previousLinkClassName="page-link"
                    nextLinkClassName="page-link"
                    breakLinkClassName="page-link"
                />
            )}

            {!orders.data?.length && (
                <div style={{ fontSize: 50, textAlign: 'center', margin: 50 }}>
                    <img src="/assets/404.svg" width="500" alt="" />
                </div>
            )}
        </div>
    )
}

export default Orders
