import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import api from '../../utilities/API'
import * as t from 'counterpart'
import './style/Sellers.scss'

function Seller() {
    const [sellers, setSellers] = useState({ data: [] })
    const store = useParams().store
    const filters = window.location.search

    useEffect(() => {
        api('/commerce/sellers/' + filters, {
            result: (data) => setSellers(data),
        })
    }, [filters])

    return (
        <div id="sellers-page" className="container">
            <h1>{t('sellers')}</h1>

            <div className="grid">
                {(sellers.data || []).map((seller) => (
                    <Link
                        key={seller.id}
                        to={'/' + store + '/browse/?seller_id=' + seller.id}
                        className="seller"
                    >
                        <img src={seller.image || '/user_image.png'} alt="" />
                        <h5>{seller.name}</h5>
                        <div className="locations">
                            {(seller.locations || []).map((location) => (
                                <div key={location.id} className="location">
                                    <div>{location.name}</div>
                                </div>
                            ))}
                        </div>
                    </Link>
                ))}
            </div>
        </div>
    )
}

export default Seller
