import React, { useState, useRef, useImperativeHandle } from 'react'
import AliceCarousel from 'react-alice-carousel'
import { Instagram } from 'react-content-loader'
import qs from 'query-string'
import api from '../../../utilities/API'
import { useOnScreen } from '../../../hooks'
import * as t from 'counterpart'

import 'react-alice-carousel/lib/alice-carousel.css'
import './style/Carousel.scss'

const Carousel = React.forwardRef(function (props = {}, forwardRef) {
    const {
        title,
        hideButtons,
        hideDots,
        cardsNumber,
        cardsNumberMobile,
        extended,
        filters,
        loadedItems,
        renderItem,
        apiEndpoint,
    } = props

    const [items, setItems] = useState(loadedItems)
    const localRef = useRef(null)
    const ref = forwardRef || localRef
    const carouselRef = useRef(null)

    useOnScreen(ref, () => !loadedItems && fetchItems(apiEndpoint, filters, setItems))

    const boxes = generateBoxes(items, renderItem, extended)
    const noResults = items && items.length === 0
    const preventButtons =  items?.length <= 1

    return (
        <div {...getDivProps(props)} ref={ref} className="carousel-block">
            <Title title={title} />
            <Content
                noResults={noResults}
                boxes={boxes}
                carouselRef={carouselRef}
                hideDots={hideDots}
                cardsNumber={cardsNumber}
                cardsNumberMobile={cardsNumberMobile}
                autoPlay={props.autoPlay}
            />
            <CarouselButtons
                noResults={noResults}
                hideButtons={hideButtons}
                carouselRef={carouselRef}
                preventButtons={preventButtons}
            />
        </div>
    )
})

const Title = ({ title }) => (title ? <h3>{title}</h3> : null)

const Content = ({
    noResults,
    boxes,
    carouselRef,
    hideDots,
    cardsNumber,
    cardsNumberMobile,
    autoPlay,
}) =>
    !noResults ? (
        <AliceCarouselWrapper
            ref={carouselRef}
            boxes={boxes}
            hideDots={hideDots}
            cardsNumber={cardsNumber}
            cardsNumberMobile={cardsNumberMobile}
            autoPlay={autoPlay}
        />
    ) : (
        <div className="no-results">{t("No results in this section right now")}</div>
    )

const fetchItems = (apiEndpoint, filters, setItems) => {
    api(
        `${apiEndpoint}/?limit=12&${typeof filters === 'string' ? filters : qs.stringify(filters)}`,
        {
            result: (res) => setItems(res.data || []),
        }
    )
}

const generateBoxes = (items, renderItem, extended) =>
    items
        ? items.map((item, key) => renderItem(item, key, extended))
        : [1, 2, 3, 4].map((key) => <Instagram key={key} className="placeholder" />)

const getDivProps = (props) => {
    const {
        title,
        hideButtons,
        hideDots,
        cardsNumber,
        cardsNumberMobile,
        extended: ext,
        filters,
        loadedItems,
        renderItem,
        apiEndpoint,
        classNamePrefix: cp,
        ...rest
    } = props
    return { ...rest }
}

const AliceCarouselWrapper = React.forwardRef(
    ({ boxes, hideDots, cardsNumber, cardsNumberMobile, autoPlay }, ref) => {
        const carouselInstance = useRef(null)

        useImperativeHandle(ref, () => ({
            slideNext: () => carouselInstance.current.slideNext(),
            slidePrev: () => carouselInstance.current.slidePrev(),
        }))

        return (
            <AliceCarousel
                autoPlay={autoPlay}
                disableDotsControls={hideDots}
                disableButtonsControls
                // animationDuration={500}
                // animationEasingFunction="ease-in-out"
                // swipeDelta={100}
                infinite
                mouseTracking
                touchTracking
                responsive={{
                    0: { items: cardsNumberMobile || 2 },
                    960: { items: cardsNumber || 4 },
                }}
                ref={carouselInstance}
            >
                {boxes}
            </AliceCarousel>
        )
    }
)

const CarouselButtons = ({ noResults, hideButtons, carouselRef, preventButtons }) =>
    !hideButtons && !noResults ? (
        <div className="buttons">
            <i
                className="left fa fa-chevron-left"
                onClick={() => {
                    if(preventButtons) return;
                    carouselRef.current.slidePrev()
                }}
            />
            <i
                className="right fa fa-chevron-right"
                onClick={() => {
                    if(preventButtons) return;
                    carouselRef.current.slideNext()}}
            />
        </div>
    ) : null

export default Carousel
