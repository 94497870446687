import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import api from '../../utilities/API'
import { Button, Modal, Table } from 'react-bootstrap'
import * as t from 'counterpart'
import { AddressForm } from 'apps/Cart/PlaceOrder/Addresses/AddressForm'
import { parseAddress } from 'apps/Cart/PlaceOrder/Addresses/Addresses'
import { Autocomplete, GoogleMap, LoadScript, Marker } from '@react-google-maps/api'

const mapLibs = ['places']

const INITIAL_ADDRESS_FORM = {
    id: null,
    name: 'Aswan',
    country: '',
    state: '',
    city: '',
    street: '',
    building: '',
    floor: '',
    apartment: '',
    neighborhood: '',
    postal_code: '',
    plot_number: '',
    registry_name: '',
    is_business: false,
    tax_number: '',
    registry_number: '',
    lat: null,
    lng: null,
    default: false,
}
function Addresses() {
    const dispatch = useDispatch()
    const user = useSelector((state) => state.portal.user)
    const [addresses, setAddresses] = useState({ data: [] })
    const [newAddressForm, setNewAddressForm] = useState(INITIAL_ADDRESS_FORM)

    const load = () => {
        api('commerce/addresses/', {
            result: (data) => setAddresses(data || {}),
        })
    }

    useEffect(() => {
        if (user) {
            load()
        }
    }, [user, dispatch])

    const deleteAddr = (id) => {
        if (!window.confirm('Delete this address?')) {
            return
        }

        api('commerce/addresses/' + id, {
            method: 'DELETE',
            result: () => load(),
        })
    }

    const editAddr = (addr) => {
        setNewAddressForm((prevState) => ({
            ...prevState,
            ...addr,
            lat: +addr.coords.split(',')[0],
            lng: +addr.coords.split(',')[1],
        }))
    }

    const onAddressFormChange = ({ target }) => {
        setNewAddressForm((prevSelected) => ({ ...prevSelected, [target.name]: target.value }))
    }

    const onSubmitAddressSucess = (data) => {
        setNewAddressForm(INITIAL_ADDRESS_FORM)
        load()
    }

    return (
        <div id="addresses" className="container">
            <h1>{t('Addresses')}</h1>

            <div className="page-body">
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>{t('Name')}</th>
                            <th>{t('State')}</th>
                            <th>{t('City')}</th>
                            <th>{t('Building')}</th>
                            <th>{t('Delete')}</th>
                            <th>{t('Edit')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {addresses.data.map((addr) => (
                            <tr key={addr.id}>
                                <td>{addr.name}</td>
                                <td>{addr.state}</td>
                                <td>{addr.city}</td>
                                <td>
                                    {addr.building}, {addr.floor}, {addr.apartment}
                                </td>
                                <td>
                                    <i
                                        className="fa fa-trash"
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => deleteAddr(addr.id)}
                                    />
                                </td>
                                <td>
                                    <i
                                        className="fa fa-edit"
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => editAddr(addr)}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>

            <Modal
                show={newAddressForm.id}
                centered
                dialogClassName="edit-address-modal"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header>
                    <div className="modal__header">
                        <div className="modal__header--title">
                            <span className="mb-1">{t('Edit Address')}</span>
                        </div>
                        <button
                            className="btn btn-close"
                            onClick={() => setNewAddressForm(INITIAL_ADDRESS_FORM)}
                        ></button>
                    </div>
                </Modal.Header>
                <Modal.Body style={{ maxHeight: '80vh', overflow: 'scroll' }}>
                    <AddressForm
                        selectedId={null}
                        setNewAddressForm={setNewAddressForm}
                        is_order_business={newAddressForm.is_business}
                        newAddressForm={newAddressForm}
                        onAddressFormChange={onAddressFormChange}
                        onSubmitAddressSucess={onSubmitAddressSucess}
                    />
                </Modal.Body>
                <Modal.Footer></Modal.Footer>
            </Modal>

            {addresses.length === 0 && <div className="empty">{t('no addresses')}</div>}
        </div>
    )
}

export default Addresses
