import React, { useState } from 'react'
import API from 'utilities/API'
import t from 'counterpart'

const GetOrderReceipt = () => {
    const [email, setEmail] = useState('')
    const [isValid, setIsValid] = useState(false)
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        return regex.test(email)
    }

    const handleInputChange = (e) => {
        const value = e.target.value
        setEmail(value)
        setIsValid(validateEmail(value))
    }

    const handleSubmit = async () => {
        if (!isValid) return

        setIsLoading(true)
        setIsSubmitted(false)

        try {
            const response = API()

            // if (response.ok) {
            //     setIsSubmitted(true)
            //     setEmail('')
            //     setIsValid(false)
            // } else {
            //     console.error(t('Failed to send email'))
            // }
        } catch (error) {
            console.error('Error:', error)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <div className="card mb-4 w-100">
            <div className="card-body">
                <h6 className="card-title text-center mb-4">{t('Get Receipt by Email')}</h6>
                <div className="form-group">
                    <input
                        className={`form-control ${email && !isValid ? 'is-invalid' : ''}`}
                        placeholder={t('Enter your email')}
                        value={email}
                        onChange={handleInputChange}
                        disabled={isLoading}
                    />
                    {email && !isValid && (
                        <div className="invalid-feedback">
                            {t('Please enter a valid email address')}
                        </div>
                    )}
                </div>
                <button
                    className="btn btn-primary w-100 mt-3"
                    onClick={handleSubmit}
                    disabled={!isValid || isLoading}
                >
                    {isLoading ? `${t('Sending')}...` : t('Send Receipt')}
                </button>
                {isSubmitted && (
                    <div className="alert alert-success mt-3 mb-0">
                        {t('Receipt sent successfully!')}
                    </div>
                )}
            </div>
        </div>
    )
}

export default GetOrderReceipt
