import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import apps from './apps'

import './admin.scss'
import { Spinner } from 'react-bootstrap'

const Setting = React.lazy(() => import('./Setting'))
const RolesForm = React.lazy(() => import('./Users/Roles'))

//
let reducers = {}
let routes = [
    <Route key="setting" exact path="/setting" element={<Setting />} />,
    <Route key="roles_create" exact path="/system/roles/create" element={<RolesForm />} />,
    <Route key="roles_edit" exact path="/system/roles/:id/edit" element={<RolesForm />} />,
]
let i18n = {
    ar: {
        Admin: 'لوحة التحكم',
        Users: 'العضويات',
        Permissions: 'الصلاحيات',
        Setting: 'الإعدادات',
        Save: 'حفظ',
        core_setting: 'إعدادات النظام',
        web_setting: 'إعدادات الواجهة',
        commerce_setting: 'إعدادات المتجر',
        broadcast_setting: 'إعدادات الإشعارات',
        payment_setting: 'إعدادات الدفع',
        cms_setting: 'إعدادات المحتوي',
        Themes: 'المظهر',
        Templates: 'القوالب',
        roles: 'الأدوار',
        erp: 'إدارة الموارد',
        overview: 'نظرة عامة',
        commerce: 'المتجر الإلكتروني',
        sales: 'المبيعات',
        purchasing: 'المشتريات',
        inventory: 'المخازن',
        accounting: 'المالية',
        assets: 'الأصول',
        manufacturing: 'التصنيع',
        hr: 'الموظفين',
        crm: 'علاقات العملاء',
        pos: 'نقاط البيع',
        'Your changes have been saved successfully': 'تم حفظ البيانات بنجاح',
        sort: 'الترتيب',
        floor: 'الطابق',
        address: 'العنوان',
        //themes
        'Product Details Layout': 'قالب تفاصيل المنتج',
        'Layout 1': 'قالب 1',
        'Layout 2': 'قالب 2',
        'Product Box Layout': 'قالب صندوق المنتج',
        'Saving Label type': 'نوع ملصق التوفير',
        value: 'قيمة',
        Discount: 'خصم',
        // permissions
        permissions_cor: 'صلاحيات النظام',
        permissions_cms: 'صلاحيات المحتوي',
        permissions_com: 'صلاحيات المتجر',
        permissions_ship: 'صلاحيات الشحن',
        permissions_pay: 'صلاحيات الدفع',
        permissions_broadcast: 'صلاحيات الإشعارات',
        permissions: 'الصلاحيات',
        permissions_erp: 'نظام إدارة الموارد',
        Blocks: 'المكونات',
        Pages: 'الصفحات',
        'Theme Settings': 'إعدادات المظهر',
    },
    en: {
        erp: 'ERP',
        hr: 'HR',
        crm: 'CRM',
        pos: 'POS',
        floor: 'Floor',
        permissions_cms: 'CMS Permissions',
        permissions_pay: 'Payment Permissions',
        permissions_broadcast: 'Broadcast Permissions',
        permissions_erp: 'ERP Permissions',
        address: 'Address',
        'Product Details Layout': 'Product Details Layout',
        'Layout 1': 'Layout 1',
        'Layout 2': 'Layout 2',
        'Product Box Layout': 'Product Box Layout',
        'Saving Label type': 'Saving Label type',
        value: 'value',
        Discount: 'Discount',
        Blocks: 'Blocks',
        Pages: 'Pages',
        'Theme Settings': 'Theme Settings',
    },
}

//
apps.forEach((app) => {
    routes = [...routes, ...app.routes]
    reducers = { ...reducers, ...app.reducers }
    i18n = {
        en: { ...i18n.en, ...app.i18n.en },
        ar: { ...i18n.ar, ...app.i18n.ar },
    }
})

//
const AdminGuard = ({ children }) => {
    const { ready, user } = useSelector((state) => state.portal)

    if (!ready) {
        return (
            <Spinner
                animation="border"
                variant="primary"
                style={{ position: 'fixed', top: '50%', left: '50%' }}
            >
                <span className="sr-only">Loading...</span>
            </Spinner>
        )
    }

    if (!user || !user.can('cor.admin.access')) {
        return <Navigate to="/" replace />
    }

    return (
        <div id="admin-area">
            <Routes>{children}</Routes>
        </div>
    )
}

//
const adminInit = {
    i18n,
    reducers,
    routes: [<Route key="admin" path="/admin/*" element={<AdminGuard>{routes}</AdminGuard>} />],
    blocks: {},
}

export default adminInit
