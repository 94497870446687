const isSaas = process.env.REACT_APP_SAAS_ENABLED === 'true'

let links = {
    //
    commerce: {
        icon: 'fa fa-shop',
        link: '/{store}/admin/commerce/manage/orders',
        permission: 'cor.admin.commerce',
        sub: {
            main: {
                links: [
                    {
                        text: 'Catalog',
                        icon: 'fa fa-boxes-stacked',
                        links: [
                            {
                                to: `/{store}/admin/commerce/manage/products`,
                                text: 'Products',
                                permission: 'com.products.update',
                            },
                            {
                                to: `/{store}/admin/commerce/manage/categories`,
                                text: 'categories',
                                permission: 'com.categories.update',
                            },
                            {
                                to: `/{store}/admin/commerce/manage/priceLists`,
                                text: 'Price Lists',
                                permission: 'com.price-lists.update',
                            },
                            {
                                to: `/{store}/admin/commerce/manage/sets`,
                                text: 'sets',
                                permission: 'com.sets.update',
                            },
                            {
                                to: `/{store}/admin/commerce/manage/attributes`,
                                text: 'attributes',
                                permission: 'com.attributes.update',
                            },
                            {
                                to: `/{store}/admin/commerce/manage/brands`,
                                text: 'brands',
                                permission: 'com.brands.update',
                            },
                            {
                                to: `/{store}/admin/commerce/manage/productsFaqs`,
                                text: 'productsFaqs',
                                permission: 'com.products-faqs.update',
                            },
                        ],
                    },
                    {
                        icon: 'fa fa-receipt',
                        text: 'Orders',
                        links: [
                            {
                                to: `/{store}/admin/commerce/manage/orders`,
                                text: 'orders',
                                permission: 'com.orders.update',
                            },
                            {
                                to: `/{store}/admin/commerce/manage/reviews`,
                                text: 'reviews',
                                permission: 'com.reviews.update',
                            },
                            {
                                to: `/{store}/admin/commerce/manage/coupons`,
                                text: 'coupons',
                                permission: 'com.coupons.update',
                            },
                        ],
                    },
                    {
                        icon: 'fa fa-store',
                        text: 'Store',
                        links: [
                            {
                                to: `/{store}/admin/commerce/manage/stores`,
                                text: 'stores',
                                permission: 'com.stores.update',
                            },
                            {
                                to: `/{store}/admin/commerce/manage/devices`,
                                text: 'devices',
                                permission: 'com.pos_devices.update',
                            },
                            {
                                to: `/{store}/admin/commerce/manage/locations`,
                                text: 'locations',
                                permission: 'com.locations.update',
                            },
                            {
                                to: `/{store}/admin/commerce/manage/shippingMethods`,
                                text: 'shippingMethods',
                                permission: 'com.shipping-methods.update',
                            },
                            {
                                to: `/{store}/admin/payment/manage/methods`,
                                text: 'Payment Methods',
                                permission: 'pay.methods.update',
                            },
                            {
                                to: `/{store}/admin/commerce/manage/sellers`,
                                text: 'sellers',
                                permission: 'com.products.update',
                            },
                            {
                                to: `/{store}/admin/commerce/manage/shifts`,
                                text: 'shifts',
                                permission: 'com.shifts.update',
                            },
                        ],
                    },

                    {
                        icon: 'fa fa-file',
                        text: 'Content Management',
                        links: [
                            {
                                to: `/{store}/admin/cms/manage/contents/pages`,
                                text: 'pages',
                                permission: 'cms.contents.update',
                            },
                            {
                                to: `/{store}/admin/cms/manage/contents/blog`,
                                text: 'blog',
                                permission: 'cms.contents.update',
                            },
                            {
                                to: `/{store}/admin/cms/themes`,
                                text: 'Themes',
                                permission: 'cms.contents.update',
                            },
                            // {
                            //     to: `/{store}/admin/cms/manage/contents/templates`,
                            //     text: 'Templates',
                            //     permission: 'cms.contents.update',
                            // },
                        ],
                    },
                ],
            },
        },
    },

    //
    erp: {
        icon: 'fa fa-book',
        link: '/{store}/admin/erp/sales#inquiry/customer|inquiry;',
        permission: 'cor.admin.erp',
        saasOnly: true,
        sub: {
            sales: {
                icon: 'fa fa-cash-register',
                link: '/{store}/admin/erp/sales#inquiry/invoices|inquiry;',
                links: [
                    {
                        text: 'invoicing',
                        icon: 'fa fa-file-invoice-dollar',
                        links: [
                            {
                                to: '/{store}/admin/erp/sales#inquiry/invoices|inquiry;',
                                text: 'فواتير المبيعات',
                            },
                            {
                                to: '/{store}/admin/erp/sales#inquiry/sales|deliveries|view;',
                                text: 'سندات التسليم',
                            },
                            {
                                to: '/{store}/admin/erp/sales#inquiry/sales|orders|view;type,30',
                                text: 'طلبات المبيعات',
                            },
                            {
                                to: '/{store}/admin/erp/sales#inquiry/sales|orders|view;type,32',
                                text: 'عروض الأسعار',
                            },
                            {
                                to: '/{store}/admin/erp/sales#inquiry/credit|notes|inquiry;',
                                text: 'مردودات المبيعات',
                            },
                        ],
                    },
                    {
                        text: 'customers',
                        icon: 'fa fa-user-group',
                        links: [
                            {
                                to: '/{store}/admin/erp/sales#manage/customers|list;',
                                text: 'إدارة العملاء',
                            },
                            {
                                to: '/{store}/admin/erp/sales#inquiry/payments|inquiry;',
                                text: 'مدفوعات العملاء',
                            },
                            {
                                to: '/{store}/admin/erp/sales#inquiry/customer|allocation|inquiry;',
                                text: 'تخصيصات العملاء',
                            },
                        ],
                    },
                    {
                        text: 'sales structure',
                        icon: 'fa fa-folder-tree',
                        links: [
                            {
                                to: '/{store}/admin/erp/sales#manage/sales|types;',
                                text: 'أنواع المبيعات',
                            },
                            {
                                to: '/{store}/admin/erp/sales#manage/sales|groups;',
                                text: 'مجموعات المبيعات',
                            },
                            {
                                to: '/{store}/admin/erp/sales#manage/sales|people;',
                                text: 'مناديب المبيعات',
                            },
                            {
                                to: '/{store}/admin/erp/sales#manage/sales|areas;',
                                text: 'مناطق البيع',
                            },
                            {
                                to: '/{store}/admin/erp/sales#manage/credit|status;',
                                text: 'الحالة الأئتمانية',
                            },
                        ],
                    },
                    {
                        text: 'sales transactions',
                        icon: 'fa fa-file',
                        to: '/{store}/admin/erp/sales#inquiry/customer|inquiry;',
                    },
                    {
                        text: 'reports',
                        icon: 'fa fa-table-list',
                        to: '/{store}/admin/erp/sales#/reporting/reports|main;Class,0&REP|ID=115',
                    },
                ],
            },
            purchasing: {
                icon: 'fa fa-basket-shopping',
                link: '/{store}/admin/erp/purchasing#inquiry/supplier|inquiry;',
                links: [
                    {
                        text: 'invoicing',
                        icon: 'fa fa-file-invoice-dollar',
                        links: [
                            {
                                to: '/{store}/admin/erp/purchasing#inquiry/invoices|inquiry;',
                                text: 'فواتير المشتريات',
                            },
                            {
                                to: '/{store}/admin/erp/purchasing#inquiry/received|inquiry;',
                                text: 'سندات الإستلام',
                            },
                            {
                                to: '/{store}/admin/erp/purchasing#inquiry/po|search|completed;',
                                text: 'أوامر الشراء',
                            },
                            {
                                to: '/{store}/admin/erp/purchasing#inquiry/credit|notes|inquiry;',
                                text: 'مردودات المشتريات',
                            },
                        ],
                    },
                    {
                        text: 'suppliers',
                        icon: 'fa fa-user',
                        links: [
                            {
                                to: '/{store}/admin/erp/purchasing#manage/suppliers|list;',
                                text: 'إدارة الموردين',
                            },
                            {
                                to: '/{store}/admin/erp/purchasing#inquiry/payments|inquiry;',
                                text: 'مدفوعات الموردين',
                            },
                            {
                                to: '/{store}/admin/erp/purchasing#inquiry/supplier|allocation|inquiry;',
                                text: 'تخصيصات الموردين',
                            },
                        ],
                    },
                    {
                        text: 'purchasing transactions',
                        icon: 'fa fa-file',
                        to: '/{store}/admin/erp/purchasing#inquiry/supplier|inquiry;',
                    },
                    {
                        text: 'reports',
                        icon: 'fa fa-table-list',
                        to: '/{store}/admin/erp/purchasing#/reporting/reports|main;Class,1&REP|ID=201',
                    },
                ],
            },
            inventory: {
                icon: 'fa fa-boxes-stacked',
                link: '/{store}/admin/erp/inventory#manage/items|list;',
                links: [
                    {
                        text: 'inventory items',
                        icon: 'fa fa-boxes-stacked',
                        links: [
                            {
                                to: '/{store}/admin/erp/inventory#inquiry/trans|inquiry;type,16',
                                text: 'التحويلات المخزنية',
                            },
                            {
                                to: '/{store}/admin/erp/inventory#inquiry/trans|inquiry;type,17',
                                text: 'التسوية المخزنية',
                            },
                            {
                                to: '/{store}/admin/erp/inventory#manage/items|list;',
                                text: 'الأصناف المخزنية',
                            },
                            {
                                to: '/{store}/admin/erp/inventory#manage/item|codes;',
                                text: 'أكواد الأصناف',
                            },
                            {
                                to: '/{store}/admin/erp/inventory#manage/sales|kits;',
                                text: 'العروض التسويقية',
                            },
                            {
                                to: '/{store}/admin/erp/inventory#inquiry/stocktake|inquiry;',
                                text: 'الجرد المخزني',
                            },
                        ],
                    },
                    {
                        text: 'inventory structure',
                        icon: 'fa fa-folder-tree',
                        links: [
                            {
                                to: '/{store}/admin/erp/inventory#manage/locations;',
                                text: 'المخازن',
                            },
                            {
                                to: '/{store}/admin/erp/inventory#manage/item|categories;',
                                text: 'التصنيفات المخزنية',
                            },
                            {
                                to: '/{store}/admin/erp/inventory#manage/item|units;',
                                text: 'وحدات القياس',
                            },
                        ],
                    },
                    {
                        text: 'inquiries',
                        icon: 'fa fa-file',
                        links: [
                            {
                                to: '/{store}/admin/erp/inventory#inquiry/stock|movements;',
                                text: 'الحركات المخزنية',
                            },
                            {
                                to: '/{store}/admin/erp/inventory#inquiry/stock|status;',
                                text: 'حالة الأصناف',
                            },
                        ],
                    },
                    {
                        text: 'reports',
                        icon: 'fa fa-table-list',
                        to: '/{store}/admin/erp/inventory#/reporting/reports|main;Class,2&REP|ID=301',
                    },
                ],
            },
            accounting: {
                icon: 'fa fa-book-bookmark',
                link: '/{store}/admin/erp/accounting#inquiry/gl|account|inquiry;',
                links: [
                    {
                        text: 'entries',
                        icon: 'fa fa-coins',
                        links: [
                            {
                                to: '/{store}/admin/erp/accounting#gl|journal;NewJournal,Yes',
                                text: 'قيد يومية',
                            },
                            {
                                to: '/{store}/admin/erp/accounting#gl|bank;NewPayment,Yes',
                                text: 'سند صرف',
                            },
                            {
                                to: '/{store}/admin/erp/accounting#gl|bank;NewDeposit,Yes',
                                text: 'سند قبض',
                            },
                            {
                                to: '/{store}/admin/erp/accounting#bank|transfer;',
                                text: 'تحويل مالي',
                            },
                            { to: '/{store}/admin/erp/accounting#accruals;', text: 'قيد إستحقاق' },
                        ],
                    },
                    {
                        text: 'financial accounts',
                        icon: 'fa fa-wallet',
                        links: [
                            {
                                to: '/{store}/admin/erp/accounting#manage/bank|accounts;',
                                text: 'الحسابات المالية',
                            },
                            {
                                to: '/{store}/admin/erp/accounting#inquiry/bank|inquiry;',
                                text: 'حركات الحسابات',
                            },
                            {
                                to: '/{store}/admin/erp/accounting#bank|account|reconcile;',
                                text: 'التسويات المالية',
                            },
                            {
                                to: '/{store}/admin/erp/accounting#manage/currencies;',
                                text: 'إدارة العملات',
                            },
                            {
                                to: '/{store}/admin/erp/accounting#manage/revaluate|currencies;',
                                text: 'تقييم العملات',
                            },
                            {
                                to: '/{store}/admin/erp/accounting#manage/exchange|rates;',
                                text: 'تبادل العملات',
                            },
                        ],
                    },
                    {
                        text: 'auditing',
                        icon: 'fa fa-magnifying-glass',
                        links: [
                            {
                                to: '/{store}/admin/erp/accounting#inquiry/journal|inquiry;',
                                text: 'دفتر اليومية',
                            },
                            {
                                to: '/{store}/admin/erp/accounting#inquiry/gl|account|inquiry;',
                                text: 'الأستاذ العام',
                            },
                            {
                                to: '/{store}/admin/erp/accounting#inquiry/gl|trial|balance;',
                                text: 'ميزان المراجعة',
                            },
                            {
                                to: '/{store}/admin/erp/accounting#inquiry/balance|sheet;',
                                text: 'المركز المالي',
                            },
                            {
                                to: '/{store}/admin/erp/accounting#inquiry/profit|loss;',
                                text: 'قائمه الدخل',
                            },
                            {
                                to: '/{store}/admin/erp/accounting#inquiry/tax|inquiry;',
                                text: 'ملخص الضريبة',
                            },
                        ],
                    },
                    {
                        text: 'accounting structure',
                        icon: 'fa fa-folder-tree',
                        links: [
                            {
                                to: '/{store}/admin/erp/accounting#manage/gl|accounts;',
                                text: 'حسابات الأستاذ العام',
                            },
                            {
                                to: '/{store}/admin/erp/accounting#manage/gl|account|types;',
                                text: 'مجموعات الحسابات',
                            },
                            {
                                to: '/{store}/admin/erp/accounting#manage/gl|account|classes;',
                                text: 'تصنيفات الحسابات',
                            },
                            {
                                to: '/{store}/admin/erp/accounting#manage/gl|quick|entries;',
                                text: 'الإدخالات السريعة',
                            },
                            {
                                to: '/{store}/admin/erp/accounting#gl|budget;',
                                text: 'الموازنات المالية',
                            },
                            {
                                to: '/{store}/admin/erp/accounting#manage/close|period;',
                                text: 'إغلاق فترة',
                            },
                        ],
                    },
                    {
                        text: 'reports',
                        icon: 'fa fa-table-list',
                        to: '/{store}/admin/erp/accounting#/reporting/reports|main;Class,6&REP|ID=601',
                    },
                ],
            },
            assets: {
                icon: 'fa fa-warehouse',
                link: '/{store}/admin/erp/assets#/inventory/manage/items|list;FixedAsset,1',
                links: [
                    {
                        text: 'operations',
                        icon: 'fa fa-boxes-stacked',
                        links: [
                            {
                                to: '/{store}/admin/erp/assets#/purchasing/po|entry|items;NewInvoice,Yes&FixedAsset,1',
                                text: 'شراء الأصول',
                            },
                            {
                                to: '/{store}/admin/erp/assets#/inventory/transfers;NewTransfer,1&FixedAsset,1',
                                text: 'نقل الأصول',
                            },
                            {
                                to: '/{store}/admin/erp/assets#/inventory/adjustments;NewAdjustment,1&FixedAsset,1',
                                text: 'إحلال الأصول',
                            },
                            {
                                to: '/{store}/admin/erp/assets#/sales/sales|order|entry;NewInvoice,0&FixedAsset,1',
                                text: 'بيع الأصول',
                            },
                            {
                                to: '/{store}/admin/erp/assets#/fixed|assets/process|depreciation;',
                                text: 'إهلاك الأصول',
                            },
                            {
                                to: '/{store}/admin/erp/inventory#inquiry/stocktake|inquiry;FixedAsset,1',
                                text: 'جرد الأصول',
                            },
                        ],
                    },
                    {
                        text: 'assets structure',
                        icon: 'fa fa-boxes-stacked',
                        links: [
                            {
                                to: '/{store}/admin/erp/assets#/inventory/manage/items|list;FixedAsset,1',
                                text: 'الأصول الثابتة',
                            },
                            {
                                to: '/{store}/admin/erp/assets#/inventory/manage/locations;FixedAsset,1',
                                text: 'مواقع الأصول',
                            },
                            {
                                to: '/{store}/admin/erp/assets#/inventory/manage/item|categories;FixedAsset,1',
                                text: 'تصنيفات الأصول',
                            },
                            {
                                to: '/{store}/admin/erp/assets#/fixed|assets/fixed|asset|classes;',
                                text: 'فئات الأصول',
                            },
                        ],
                    },
                    {
                        text: 'inquiries',
                        icon: 'fa fa-boxes-stacked',
                        links: [
                            {
                                to: '/{store}/admin/erp/assets#/inventory/inquiry/stock|movements;FixedAsset,1',
                                text: 'حركات الأصول',
                            },
                            {
                                to: '/{store}/admin/erp/assets#/fixed|assets/inquiry/stock|inquiry;',
                                text: 'حالة الأصول',
                            },
                        ],
                    },
                    {
                        text: 'reports',
                        icon: 'fa fa-table-list',
                        to: '/{store}/admin/erp/assets#/reporting/reports|main;Class,7&REP|ID=451',
                    },
                ],
            },
            manufacturing: {
                icon: 'fa fa-industry',
                link: '/{store}/admin/erp/manufacturing#search|work|orders;',
                links: [
                    {
                        icon: 'fa fa-network-wired',
                        to: '/{store}/admin/erp/manufacturing#work|order|items;',
                        text: 'manufacturing operations',
                    },
                    {
                        icon: 'fa fa-arrow-down-wide-short',
                        to: '/{store}/admin/erp/manufacturing#search|work|orders;',
                        text: 'work orders',
                    },
                    {
                        text: 'manufacturing structure',
                        icon: 'fa fa-folder-tree',
                        links: [
                            {
                                to: '/{store}/admin/erp/manufacturing#manage/work|centres;',
                                text: 'مراكز العمل',
                            },
                            {
                                to: '/{store}/admin/erp/manufacturing#manage/processes;',
                                text: 'مراحل التصنيع',
                            },
                            {
                                to: '/{store}/admin/erp/manufacturing#manage/lines;',
                                text: 'خطوط الإنتاج',
                            },
                        ],
                    },
                    {
                        text: 'materials',
                        icon: 'fa fa-clipboard-list',
                        links: [
                            {
                                to: '/{store}/admin/erp/manufacturing#manage/bom|edit;',
                                text: 'قوائم التركيب',
                            },
                            {
                                to: '/{store}/admin/erp/manufacturing#inquiry/bom|cost|inquiry;',
                                text: 'تكاليف قوائم التركيب',
                            },
                            {
                                to: '/{store}/admin/erp/manufacturing#inquiry/where|used|inquiry;',
                                text: 'إستخدمات المواد الخام',
                            },
                        ],
                    },
                    {
                        text: 'reports',
                        icon: 'fa fa-table-list',
                        to: '/{store}/admin/erp/manufacturing#/reporting/reports|main;Class,3&REP|ID=401',
                    },
                ],
            },
            hr: {
                icon: 'fa fa-user-group',
                link: '/{store}/admin/erp/hr#manage/attendance;',
                links: [
                    {
                        text: 'hr structure',
                        icon: 'fa fa-boxes-stacked',
                        links: [
                            { to: '/{store}/admin/erp/hr#manage/employee;', text: 'الموظفين' },
                            { to: '/{store}/admin/erp/hr#manage/department;', text: 'أقسام الجهة' },
                            {
                                to: '/{store}/admin/erp/hr#manage/accounts;',
                                text: 'حسابات البدلات والجزاءات',
                            },
                            {
                                to: '/{store}/admin/erp/hr#manage/payroll|rules;',
                                text: 'سياسة البدلات والجزاءات',
                            },
                            {
                                to: '/{store}/admin/erp/hr#manage/default|setup;',
                                text: 'الإعدادات العامة',
                            },
                        ],
                    },
                    {
                        text: 'Attendance',
                        icon: 'fa fa-boxes-stacked',
                        links: [
                            { to: '/{store}/admin/erp/hr#inquiry/time|sheet;', text: 'سجل الدوام' },
                            {
                                to: '/{store}/admin/erp/hr#manage/attendance;',
                                text: 'الحضور والإنصراف',
                            },
                            {
                                to: '/{store}/admin/erp/hr#manage/overtime;',
                                text: 'أنواع العمل الإضافي',
                            },
                        ],
                    },
                    {
                        text: 'salaries',
                        icon: 'fa fa-boxes-stacked',
                        links: [
                            {
                                to: '/{store}/admin/erp/hr#manage/payslip;NewPayslip,Yes',
                                text: 'قسيمة راتب',
                            },
                            {
                                to: '/{store}/admin/erp/hr#manage/salaryscale;',
                                text: 'توزيع الرواتب',
                            },
                            {
                                to: '/{store}/admin/erp/hr#manage/salary|structure;',
                                text: 'إعلانات الرواتب',
                            },
                            {
                                to: '/{store}/admin/erp/hr#manage/pay|advice;NewPaymentAdvice,Yes',
                                text: 'إشعار الدفع',
                            },
                        ],
                    },
                    {
                        icon: 'fa fa-boxes-stacked',
                        to: '/{store}/admin/erp/hr#inquiry/emp|inquiry;',
                        text: 'payments',
                    },
                ],
            },
            // crm: {
            //     icon: "fa fa-handshake-simple", link: "/{store}/admin/erp/crm#inquires/reminders;",
            //     links: [
            //         {
            //             text: "الفواتير",
            //             icon: "fa fa-boxes-stacked",
            //             links: [
            //                 {to: "/{store}/admin/erp/crm#estimates;NewEstimate,Yes", text: "Estimates"},
            //                 {to: "/{store}/admin/erp/crm#manage/leads;", text: "Add And Manage Leads"},
            //                 {to: "/{store}/admin/erp/crm#manage/attachments;", text: "المرفقات"},
            //                 {to: "/{store}/admin/erp/crm#tasks/", text: "Tasks"},
            //             ]
            //         }, {
            //             text: "الفواتير",
            //             icon: "fa fa-boxes-stacked",
            //             links: [
            //                 {to: "/{store}/admin/erp/crm#estimates;NewProposal,Yes", text: "Proposals"},
            //                 {to: "/{store}/admin/erp/crm#invoice;NewInvoice,Yes", text: "Project Invoice"},
            //                 {to: "/{store}/admin/erp/crm#manage/opportunities;", text: "Add Manage Opportunites"},
            //                 {to: "/{store}/admin/erp/crm#projects/", text: "Projects"},
            //                 {to: "/{store}/admin/erp/crm#inquires/reminders;", text: "Reminders"},
            //             ]
            //         }, {
            //             text: "الفواتير",
            //             icon: "fa fa-boxes-stacked",
            //             links: [
            //                 {to: "/{store}/admin/erp/crm#inquires/reminders;medium,1", text: "Calls"},
            //                 {to: "/{store}/admin/erp/crm#inquires/customer|inquiry;", text: "Customers Inquiry"},
            //                 {to: "/{store}/admin/erp/crm#inquires/supplier|inquiry;", text: "Suppliers Inquiry"},
            //                 {to: "/{store}/admin/erp/crm#inquires/proposal|estimate|inquiry;type,33", text: "Estimates Inquiry"},
            //                 {to: "/{store}/admin/erp/crm#inquires/proposal|estimate|inquiry;type,34", text: "Proposals Inquiry"},
            //                 {to: "/{store}/admin/erp/crm#inquires/reminders;medium,2", text: "Meetings"},
            //             ]
            //         }, {
            //             text: "الفواتير",
            //             icon: "fa fa-boxes-stacked",
            //             links: [
            //                 {to: "/{store}/admin/erp/crm#inquires/opportunities|inquiry;", text: "Opportunities Inquiry"},
            //                 {to: "/{store}/admin/erp/crm#inquires/leads|inquiry;", text: "Leads Inquiry"},
            //                 {to: "/{store}/admin/erp/crm#projects/inquiry;", text: "Projects Inquiry"},
            //                 {to: "/{store}/admin/erp/crm#tasks/inquiry;", text: "Tasks Inquiry"},
            //             ]
            //         }, {
            //             text: "الفواتير",
            //             icon: "fa fa-boxes-stacked",
            //             links: [
            //                 {to: "/{store}/admin/erp/crm#reports/crm|reports;Class,11&amp;REP|ID,120", text: "CRM Reports"},
            //                 {to: "/{store}/admin/erp/crm#manage/customers;", text: "العملاء"},
            //                 {to: "/{store}/admin/erp/crm#manage/customer|branches;", text: "فروع العميل"},
            //                 {to: "/{store}/admin/erp/crm#picklists;", text: "Pick Lists"},
            //                 {to: "/{store}/admin/erp/crm#PickType;", text: "Pick Types"},
            //             ]
            //         }, {
            //             text: "الفواتير",
            //             icon: "fa fa-boxes-stacked",
            //             links: [
            //                 {to: "/{store}/admin/erp/crm#manage/suppliers;", text: "الموردين"},
            //                 {to: "/{store}/admin/erp/crm#manage/email|templates;", text: "Email Templates"},
            //                 {to: "/{store}/admin/erp/crm#import|export;", text: "Import Export CSV"},
            //                 {to: "/{store}/admin/erp/crm#manage/crm|settings;", text: "Settings"},
            //             ]
            //         }
            //     ]
            // }
        },
    },

    //
    pos: {
        icon: 'fa fa-tv',
        link: '/{store}/pos',
        permission: 'cor.admin.pos',
        sub: {},
    },

    //
    setting: {
        icon: 'fa fa-gear',
        link: '/{store}/admin/setting',
        permission: 'cor.setting.update',
        sub: {
            main: {
                links: [
                    {
                        text: 'General Setting',
                        icon: 'fa fa-table-list',
                        to: '/{store}/admin/setting',
                        permission: 'cor.setting.update',
                    },
                    {
                        text: 'Branches',
                        icon: 'fa fa-boxes-stacked',
                        saasOnly: true,
                        links: [
                            {
                                to: '/{store}/admin/erp/setting#dimensions/inquiry/search|dimensions;',
                                text: 'قائمة القطاعات',
                            },
                            {
                                to: '/{store}/admin/erp/setting#dimensions/dimension|entry;',
                                text: 'إدخال قطاع',
                            },
                            {
                                to: '/{store}/admin/erp/setting#dimensions/inquiry/search|dimensions;outstanding|only,1',
                                text: 'القطاعات المنتهية',
                            },
                            {
                                to: '/{store}/admin/erp/setting#sys!tags;type,dimension',
                                text: 'الكلمات الدلالية',
                            },
                        ],
                    },
                    {
                        text: 'Finance',
                        icon: 'fa fa-boxes-stacked',
                        saasOnly: true,
                        links: [
                            {
                                to: '/{store}/admin/erp/setting#sys!gl|setup;',
                                text: 'الأستاذ العام',
                            },
                            {
                                to: '/{store}/admin/erp/setting#sys!fiscalyears;',
                                text: 'السنوات المالية',
                            },
                            { to: '/{store}/admin/erp/setting#taxes/tax|types;', text: 'الضرائب' },
                            {
                                to: '/{store}/admin/erp/setting#taxes/tax|groups;',
                                text: 'مجموعات الضرائب',
                            },
                            {
                                to: '/{store}/admin/erp/setting#taxes/item|tax|types;',
                                text: 'أنواع ضرائب البنود المخزنية',
                            },
                        ],
                    },
                    {
                        text: 'Transactions',
                        icon: 'fa fa-boxes-stacked',
                        saasOnly: true,
                        links: [
                            {
                                to: '/{store}/admin/erp/setting#sys!void|transaction;',
                                text: 'إلغاء حركة',
                            },
                            {
                                to: '/{store}/admin/erp/setting#sys!view|print|transaction;',
                                text: 'عرض أو طباعة الحركات',
                            },
                            {
                                to: '/{store}/admin/erp/setting#sys!attachments;filterType,20',
                                text: 'أرفاق المستندات',
                            },
                        ],
                    },
                    {
                        text: 'erp',
                        icon: 'fa fa-boxes-stacked',
                        saasOnly: true,
                        links: [
                            {
                                to: '/{store}/admin/erp/setting#sys!company|preferences;',
                                text: 'إعدادات الجهة',
                            },
                            {
                                to: '/{store}/admin/erp/setting#sys!users;',
                                text: 'إدارة المستخدمين',
                            },
                            {
                                to: '/{store}/admin/erp/setting#sys!payment|terms;',
                                text: 'شروط الدفع',
                            },
                            {
                                to: '/{store}/admin/erp/setting#sys!shipping|companies;',
                                text: 'شركة الشحن',
                            },
                            {
                                to: '/{store}/admin/erp/setting#sales/manage/sales|points;',
                                text: 'نقاط البيع',
                            },
                            {
                                to: '/{store}/admin/erp/setting#sys!crm|categories;',
                                text: 'تصنيفات الاتصالات',
                            },

                            {
                                to: '/{store}/admin/erp/setting#sys!display|prefs;',
                                text: 'إعدادات العرض',
                            },
                        ],
                    },
                    {
                        icon: 'fa fa-user-group',
                        text: 'Users',
                        links: [
                            {
                                to: `/{store}/admin/system/manage/users`,
                                text: 'Users',
                                permission: 'cor.users.update',
                            },
                            {
                                to: `/{store}/admin/system/manage/roles`,
                                text: 'Permissions',
                                permission: 'cor.roles.update',
                            },
                        ],
                    },
                    {
                        icon: 'fa fa-comment',
                        text: 'Notifications',
                        links: [
                            {
                                to: `/{store}/admin/broadcast/manage/templates`,
                                text: 'templates',
                                permission: 'broadcast.templates.update',
                            },
                            // {to: `/{store}/admin/broadcast/send`, text: "Send Message", permission: "broadcast.send"},
                        ],
                    },
                ],
            },
        },
    },
}

function saasFilter(obj) {
    for (let key in obj) {
        if (typeof obj[key] === 'object' && obj[key] !== null) {
            if (obj[key].hasOwnProperty(isSaas ? 'saasHide' : 'saasOnly')) {
                delete obj[key]
            } else {
                saasFilter(obj[key])
            }
        }
    }
}

saasFilter(links)

export default links
